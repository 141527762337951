<template>
  <full-view :title="getTitle">
    <template v-slot:toolbar>
      <v-btn :disabled="!valid" large depressed color="primary" @click="save">
        {{ $t("general.save") }}
      </v-btn>
    </template>

    <v-card flat>
      <v-card-text class="overflow-y-auto pa-0" :style="getViewHeightStyle(56)">
        <v-form ref="form" v-model="valid" class="mb-5">
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-text-field
                    v-model="item.name"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.name')"
                    required
                    dense
                  />
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="item.nameAr"
                    :counter="64"
                    :rules="rules.name"
                    :label="$t('general.nameAr')"
                    required
                    dense
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-textarea
                    v-model="item.description"
                    :counter="255"
                    :rules="rules.limited255"
                    :label="$t('general.description')"
                    dense
                    rows="3"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <!-- <v-select
                    v-model="item.sectionId"
                    :items="getSectionsByProp('storeId', this.getSectionIdParam)"
                    :item-text="getLocale.lang === 'ar' ? 'nameAr' : 'name'"
                    item-value="id"
                    :rules="rules.required"
                    :label="$t('store.owner')"
                    required
                    dense
                  ></v-select> -->
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="item.active"
                    :label="$t('general.active')"
                    dense
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <br />
          <form-bar
            :valid="valid"
            @save="save"
            @reset="reset"
            @validate="$refs.form.validate()"
          />
        </v-form>
      </v-card-text>
    </v-card>
  </full-view>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FullView from "@/common/layouts/FullView";
import ImageUploader from "@/common/components/ImageUploader";
import FormBar from "@/common/components/FormBar";

export default {
  name: "CategoryForm",
  components: { FullView, ImageUploader, FormBar },
  data() {
    return {
      item: {},
      valid: false,
      originalState: { id: 0, sectionId: this.getSectionIdParam },
      rules: {
        name: [
          (v) => !!v || "First name is required",
          (v) => (!!v && v.length >= 5) || "Name must be at least 5 characters",
          (v) =>
            (!!v && v.length <= 64) ||
            "Must be less than or eaqual to 64 characters",
        ],
        limited255: [
          (v) =>
            !v || v.length <= 255 || "This field is limited to 255 characters",
        ],
      },
    };
  },
  mounted() {
    // this.loadSectionByProp({ prop: "storeId", value: this.getStoreIdParam });
    if (this.editMode) {
      this.loadCategoryByUuid(this.uuidParam).then((item) => {
        this.item = item;
        Object.assign(this.originalState, this.item);
      });
    }
  },
  computed: {
    ...mapGetters("categories", ["getCategoryById"]),
    ...mapGetters("sections", ["getSectionsByProp"]),

    getTitle() {
      if (this.editMode) {
        return this.$t("store.section.edit");
      } else {
        return this.$t("store.section.new");
      }
    },
    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    getStoreIdParam() {
      return parseInt(this.$route.params.storeId);
    },
    getSectionUuidParam() {
      return this.$route.params.sectionUuid;
    },
    editMode() {
      return !!this.getSectionIdParam;
    },
    isAdmin() {
      return this.$hasRole("SUPER-ADMIN") || this.$hasRole("ADMIN");
    },
  },
  methods: {
    ...mapActions("categories", ["loadCategoryById", "saveCategory"]),
    ...mapActions("sections", ["loadSectionsByProp"]),

    save() {
      this.saveCategory({
        item: this.item,
        editMode: this.editMode,
      }).then(() => {
        this.$$router.back();
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
